export var yarnNumberlist = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];


export var ChromaticityList = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];


export var washing = [
  {
    WashingType: "水洗",
    arr: [
      {
        value: "1",
        label: "最高水温30度",
      },
      {
        value: "2",
        label: "30度温和洗涤",
      },
      {
        value: "3",
        label: "30度非常温和洗涤",
      },
      {
        value: "4",
        label: "最高水温40度",
      },
      {
        value: "5",
        label: "40度温和洗涤",
      },
      {
        value: "6",
        label: "40度非常温和洗涤",
      },
      {
        value: "7",
        label: "最高水温50度",
      },
      {
        value: "8",
        label: "50度温和洗涤",
      },
      {
        value: "9",
        label: "50度非常温和洗涤",
      },
      {
        value: "10",
        label: "可手洗",
      },
      {
        value: "11",
        label: "禁止水洗",
      },
    ],
    value: '',
  },
  {
    WashingType: "干洗",
    arr: [
      {
        value: "12",
        label: "碳氢化合物溶剂",
      },
      {
        value: "13",
        label: "碳氢化合物溶剂温和洗涤",
      },
      {
        value: "14",
        label: "四氯乙烯溶剂",
      },
      {
        value: "15",
        label: "四氯乙烯溶剂温和洗涤",
      },
      {
        value: "16",
        label: "可干洗",
      },
    ],
    value: '',
  },
  {
    WashingType: "烘干",
    arr: [
      {
        value: "17",
        label: "可烘干",
      },
      {
        value: "18",
        label: "低温烘干",
      },
      {
        value: "19",
        label: "常温烘干",
      },
      {
        value: "20",
        label: "不可烘干",
      },
    ],
    value: '',
  },
  {
    WashingType: "漂白",
    arr: [
      {
        value: "21",
        label: "可漂白",
      },
      {
        value: "22",
        label: "不可漂白",
      },
    ],
    value: '',
  },
   {
    WashingType: "熨烫",
    arr: [
      {
        value: "23",
        label: "可熨烫",
      },
      {
        value: "24",
        label: "低温熨烫",
      },
      {
        value: "25",
        label: "中温熨烫",
      },
      {
        value: "26",
        label: "高温熨烫",
      },
      {
        value: "27",
        label: "不可熨烫",
      }, {
        value: "28",
        label: "垫布熨烫",
      },
    ],
    value: '',
  },
  {
    WashingType: "晾干",
    arr: [
      {
        value: "29",
        label: "悬挂滴干",
      },
      {
        value: "30",
        label: "悬挂晾干",
      },
      {
        value: "31",
        label: "平摊滴干",
      },
      {
        value: "32",
        label: "平摊晾干",
      },
      {
        value: "33",
        label: "阴凉处悬挂滴干",
      },
      {
        value: "34",
        label: "阴凉处悬挂晾干",
      },
      {
        value: "35",
        label: "阴凉处平摊滴干",
      },
      {
        value: "36",
        label: "阴凉处平摊晾干",
      },
    ],
    value: '',
  },
]
export var washingImg = [
  {
    WashingType: "washRinsing",
    arr: [
      {
        path: require('@/images/wash/01.png'),
        name: "最高水温30度",
      },
      {
        path: require('@/images/wash/02.png'),
        name: "30度温和洗涤",
      },
      {
        path: require('@/images/wash/03.png'),
        name: "30度非常温和洗涤",
      },
      {
        path: require('@/images/wash/04.png'),
        name: "最高水温40度",
      },
      {
        path: require('@/images/wash/05.png'),
        name: "40度温和洗涤",
      },
      {
        path: require('@/images/wash/06.png'),
        name: "40度非常温和洗涤",
      },
      {
        path: require('@/images/wash/07.png'),
        name: "最高水温50度",
      },
      {
        path: require('@/images/wash/08.png'),
        name: "50度温和洗涤",
      },
      {
        path: require('@/images/wash/09.png'),
        name: "50度非常温和洗涤",
      },
      {
        path: require('@/images/wash/10.png'),
        name: "可手洗",
      },
      {
        path: require('@/images/wash/11.png'),
        name: "禁止水洗",
      },
    ],
  },
  {
    WashingType: "washDryClean",
    arr: [
      {
        path: require('@/images/wash/12.png'),
        name: "碳氢化合物溶剂",
      },
      {
        path: require('@/images/wash/13.png'),
        name: "碳氢化合物溶剂温和洗涤",
      },
      {
        path: require('@/images/wash/14.png'),
        name: "四氯乙烯溶剂",
      },
      {
        path: require('@/images/wash/15.png'),
        name: "四氯乙烯溶剂温和洗涤",
      },
      {
        path: require('@/images/wash/16.png'),
        name: "可干洗",
      },
    ],
  },
  {
    WashingType: "washDrying",
    arr: [
      {
        path: require('@/images/wash/17.png'),
        name: "可烘干",
      },
      {
        path: require('@/images/wash/18.png'),
        name: "低温烘干",
      },
      {
        path: require('@/images/wash/19.png'),
        name: "常温烘干",
      },
      {
        path: require('@/images/wash/20.png'),
        name: "不可烘干",
      },
    ],
  },
  {
    WashingType: "washAiring",
    arr: [
      {
        path: require('@/images/wash/21.png'),
        name: "悬挂滴干",
      },
      {
        path: require('@/images/wash/22.png'),
        name: "悬挂晾干",
      },
      {
        path: require('@/images/wash/23.png'),
        name: "平摊滴干",
      },
      {
        path: require('@/images/wash/24.png'),
        name: "平摊晾干",
      },
      {
        path: require('@/images/wash/25.png'),
        name: "阴凉处悬挂滴干",
      },
      {
        path: require('@/images/wash/26.png'),
        name: "阴凉处悬挂晾干",
      },
      {
        path: require('@/images/wash/27.png'),
        name: "阴凉处平摊滴干",
      },
      {
        path: require('@/images/wash/28.png'),
        name: "阴凉处平摊晾干",
      },
    ],
  },
  {
    WashingType: "washBleach",
    arr: [
      {
        path: require('@/images/wash/29.png'),
        name: "可漂白",
      },
      {
        path: require('@/images/wash/30.png'),
        name: "不可漂白",
      },
    ],
  },
  {
    WashingType: "washIroning",
    arr: [
      {
        path: require('@/images/wash/31.png'),
        name: "可熨烫",
      },
      {
        path: require('@/images/wash/32.png'),
        name: "低温熨烫",
      },
      {
        path: require('@/images/wash/33.png'),
        name: "中温熨烫",
      },
      {
        path: require('@/images/wash/34.png'),
        name: "高温熨烫",
      },
      {
        path: require('@/images/wash/35.png'),
        name: "不可熨烫",
      }, {
        path: require('@/images/wash/36.png'),
        name: "垫布熨烫",
      },
    ],
  },
]
export var structure = [
  require('@/images/structure/01.png'),
  require('@/images/structure/02.png'),
  require('@/images/structure/03.png'),
  require('@/images/structure/04.png'),
  require('@/images/structure/05.png'),
  require('@/images/structure/06.png'),
  require('@/images/structure/07.png'),
  require('@/images/structure/08.png'),
  require('@/images/structure/09.png'),
  require('@/images/structure/10.png'),
  require('@/images/structure/11.png'),
  require('@/images/structure/12.png'),
  require('@/images/structure/13.png'),
  require('@/images/structure/14.png'),
  require('@/images/structure/15.png'),
  require('@/images/structure/16.png'),
  require('@/images/structure/17.png'),
  require('@/images/structure/18.png'),
  require('@/images/structure/19.png'),
  require('@/images/structure/20.png'),
  require('@/images/structure/21.png'),
  require('@/images/structure/22.png'),
  require('@/images/structure/23.png'),
  require('@/images/structure/24.png'),
  require('@/images/structure/25.png'),
  require('@/images/structure/26.png'),
]
export var greyStructure = [
  require('@/images/structure/27.png'),
  require('@/images/structure/28.png'),
  require('@/images/structure/29.png'),
  require('@/images/structure/30.png'),
  require('@/images/structure/31.png'),
  require('@/images/structure/32.png'),
  require('@/images/structure/33.png'),
  require('@/images/structure/34.png'),
  require('@/images/structure/35.png'),
  require('@/images/structure/36.png'),
  require('@/images/structure/37.png'),
  require('@/images/structure/38.png'),
  require('@/images/structure/39.png'),
  require('@/images/structure/40.png'),
  require('@/images/structure/41.png'),
  require('@/images/structure/42.png'),
  require('@/images/structure/43.png'),
  require('@/images/structure/44.png'),
  require('@/images/structure/45.png'),
  require('@/images/structure/46.png'),
  require('@/images/structure/47.png'),
  require('@/images/structure/48.png'),
  require('@/images/structure/49.png'),
  require('@/images/structure/50.png'),
  require('@/images/structure/51.png'),
  require('@/images/structure/52.png'),
]

export var aa = {
  width: 10,
  height: 5,
  arr: [
    ['u', 'k', 'n'],
    ['u', 'k', 'n'],
  ],
  keyList: ['u', 'k', 'n']
}

export var keyList = [
  {
    name: 'aa',
    width: 10,
    height: 10,
    list: [[1, 2, 3], [1, 2, 3]],
    canvasKey: 'k'
  }
]
